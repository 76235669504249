export const applicationES = {
    title: 'Central de Actualizaciones - Protheus',
    title_slide: 'Notificaciones',
    title_slide_config: 'Gestión de notificaciones',
    my_environment: 'Mi ambiente',
    environment_label: 'Ambiente',
    sub_item_packages: 'Paquetes aplicados',
    sub_item_packages_1: 'Paquete x Fuente',
    sub_item_packages_2: 'Fuente x Paquete',
    sub_item_dictionary: 'Diccionario',
    sub_item_procedures: 'Procedures',
    sub_item_procedures_1: 'Empresa x Proceso',
    sub_item_procedures_2: 'Proceso x Empresa',
    available_updates: 'Actualizaciones disponibles',
    available_updates_label: 'Actualizaciones',
    sub_item_accumulated_packages: 'Paquetes acumulados',
    sub_item_module_packages: 'Paquetes del módulo',
    sub_item_source_list: 'Lista del fuentes',
    sub_item_lib_and_bin: 'Lib y Binario',
    suggestions: 'Sugerencias',
    suggestions_label: 'Sugerencias',
    notification_config_title: 'Ajustes',
    notifications_label: 'Notificaciones',
    notifications_settings: 'Administrar suscripciones',
    notifications_columns_title: ['Data', 'Título', 'Resumen'],
    msg_session_expired: 'Sesión expirada! Por favor, abre la página de nuevo.',
    msg_session_unauthorized: 'Sesión no autorizada! Por favor ingrese de nuevo.',
    msg_request_incomplete: 'No fue posible completar la solicitud',
    subscriptions: 'Inscripciones',
    subscriptions_label: 'Inscripciones a Notificaciones',
    theme_light: 'Claro',
    theme_dark: 'Oscuro',

    //-- literais usadas no componente "dynamic-search-page"
    dsp_filter_title: 'Búsqueda avanzada',
    dsp_group_title: 'Filtros aplicados',
    dsp_cancel_btn: 'Cancelar',
    dsp_confirm_btn: 'Aplicar',
    dsp_search_placeholder: 'busca aquí',
    dsp_details: 'Detalles',
    dsp_tdn_documentation: 'Documentación TDN',
    dsp_tdn_documentation_details: 'Doc. TDN',
    dsp_no_package_documentation: 'Para el paquete seleccionado no hay documentación disponible',
    dsp_hlp_how_to_use: '¿Cómo usar?',
    dsp_hlp_resume: 'Resumen',
    dsp_hlp_search: 'Búsqueda',

}
