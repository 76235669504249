export const sourcesES = {
    title: 'Fuentes aplicados',

    status_outdated_value: 'RPO Desatualizado', // não podem ser traduzidos pois são os valores da query da API rpo_list.php
    status_uptodate_value: 'RPO Atualizado', // não podem ser traduzidos pois são os valores da query da API rpo_list.php
    status_outdated_label: 'Desactualizado',
    status_uptodate_label: 'Actualizado',

    column_source: 'Fuente',
    column_patch_date: 'Fecha Patch',
    column_patch_time: 'Hora Patch',
    column_rpo_date: 'Fecha RPO',
    column_rpo_time: 'Hora RPO',

    details_id: 'Id Patch',
    details_ticket: 'Ticket',
    details_issue: 'Issue',
    details_module: 'Módulo',
    details_generation_date: 'Fecha generación',
    details_issue_type: 'Tipo del issue',
    details_resume: 'Resumen',

    type_innovation: 'Innovación',
    type_maintenance: 'Mantenimiento',
    type_continuous_delivery: 'Expedición continúa',

    filter_source: 'Fuente',
    
    sources_texthelp:   `
                            <strong>Objetivo:</strong>
                            <p class="text-justify">Muestra la lista de fuentes actualizadas por paquetes aplicados en el ambiente indicado en la página de inicio, así como cuales paquetes ha actualizado cada fuente.</p>
                            <br>
                            <strong>Campos:</strong>
                            <p class="text-justify">-Fuente: Nombre del fuente;</p>
                            <p class="text-justify">-Fecha Patch: Fecha mas actualizada de los patchs aplicados;</p>
                            <p class="text-justify">-Hora Patch: Hora mas actualizada de los patchs aplicados;</p>
                            <p class="text-justify">-Fecha RPO: Fecha del fuente en el rpo, normalmente el mismo que el patch, a menos que haya aceptado la aplicación de fuentes anteriores o haya aplicado un patch de emergencia;</p>
                            <p class="text-justify">-Hora RPO: Hora del fuente en el rpo;</p>
                            <br>
                            <strong>Campos de Búsqueda/Filtro:</strong>
                            <p class="text-justify">Por fuente.</p>
                            <br>
                            <strong>Mas opciones:</strong>
                            <p class="text-justify">-Detalles: Muestra la lista de los paquetes que contiene el fuente.</p>
                        `,
}
