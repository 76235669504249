export const sourcesPT = {
    title: 'Fontes aplicados',

    status_outdated_value: 'RPO Desatualizado',
    status_uptodate_value: 'RPO Atualizado',
    status_outdated_label: 'Desatualizado',
    status_uptodate_label: 'Atualizado',

    column_source: 'Fonte',
    column_patch_date: 'Data Patch',
    column_patch_time: 'Hora Patch',
    column_rpo_date: 'Data RPO',
    column_rpo_time: 'Hora RPO',

    details_id: 'Id Patch',
    details_ticket: 'Ticket',
    details_issue: 'Issue',
    details_module: 'Módulo',
    details_generation_date: 'Data geração',
    details_issue_type: 'Tipo de issue',
    details_resume: 'Resumo',

    type_innovation: 'Inovação',
    type_maintenance: 'Manutenção',
    type_continuous_delivery: 'Expedição contínua',

    filter_source: 'Source',
    
    sources_texthelp:   `
                            <strong>Objetivo:</strong>
                            <p class="text-justify">Exibir a lista dos fontes atualizados por pacotes aplicados no ambiente indicado na página home, bem como em quais pacotes cada fonte teve atualização.</p>
                            <br>
                            <strong>Campos:</strong>
                            <p class="text-justify">-Fonte: Nome do fonte;</p>
                            <p class="text-justify">-Data Patch: Data mais atualizada dos patchs aplicados;</p>
                            <p class="text-justify">-Hora Patch: Hora mais atualizada dos patchs aplicados;</p>
                            <p class="text-justify">-Data RPO: Data do fonte no rpo, normalmente igual ao patch a menos que tenha aceitado a aplicação de fontes mais antigos ou aplicado um patch emergencial;</p>
                            <p class="text-justify">-Hora RPO: Hora do fonte no rpo;</p>
                            <br>
                            <strong>Campos para Busca/Filtro:</strong>
                            <p class="text-justify">Por fonte.</p>
                            <br>
                            <strong>Mais opções:</strong>
                            <p class="text-justify">-Detalhes: Mostra a lista de pacotes que contém o fonte.</p>
                        `,
}
